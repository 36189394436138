<template>
  <div>
    <div class="mb-2 flex justify-end">
      <SocketStatus />
    </div>
    <LazyToastNotification v-if="showUserJoin" :show-toast="showUserJoin">
      @{{ userJoined }} has joined the draw
    </LazyToastNotification>
    <DrawHeader
      :draw-name="drawName"
      :can-exist-draw="canExistDraw"
      :loading="loading.exist"
      @on-exit="openConfirmModal"
    />
    <DrawStats
      class="mt-5"
      :participants="totalDrawParticipants"
      :joined="members.length"
      :amount="amountToBeWon"
    />
    <div
      class="mt-5 rounded-lg border border-grey bg-white p-5 shadow-custom dark:border-grey/10 dark:bg-dark-secondary"
    >
      <LazyDrawCountDown v-if="isCountingDown" :countdown="timerCountDown" />
      <template v-else>
        <p
          class="text-center text-sm uppercase text-light-text dark:text-dark-light-text"
        >
          {{
            members.length === totalDrawParticipants
              ? 'Draw in Action'
              : 'Participants in Draw'
          }}
        </p>
        <div
          v-if="winner"
          class="mt-5 animate-bounce text-center text-lg font-bold"
        >
          @{{ winner }}
        </div>
        <template v-if="!winner">
          <LazyBounceDrawAnimation
            v-if="animationType === 'bounce'"
            :members="members"
            :participant-index="drawParticipantIndex"
          />
          <LazyShuffleDrawAnimation
            v-if="animationType === 'shuffle'"
            :members="members"
            :placeholder="nameToShuffleFrom"
            :has-shuffle-animation-started="hasShuffleAnimationStarted"
          />
          <LazySlideUpAnimation
            v-if="animationType === 'slide-up'"
            :first-value="firstValue"
            :second-value="secondValue"
            :members="members"
            :has-slide-animation-started="hasSlideAnimationStarted"
          />
        </template>
      </template>
    </div>

    <LazyDrawWinnerOverlay
      v-if="showDrawOverlay"
      ref="drawWinnerOverlay"
      :nickname="winner"
      :amount="amountToBeWon"
      :is-winner="isCurrentLoggedInUserWinner"
      @go-back-to-draw-listing="redirectToHomePage"
    />
    <LazyConfirmModal
      v-if="showConfirmModal"
      ref="confirmModal"
      title="Are you sure you want to exit this draw?"
      :is-loading="loading.exist"
      @proceed="exitDraw"
    />
  </div>
</template>

<script setup lang="ts">
import type DrawWinnerOverlay from '@/components/pages/games/draw/quickdraw/DrawWinnerOverlay.vue'
import type ConfirmModal from '~/components/modals/ConfirmModal.vue'

// Reusable Composables
const app = useAppStore()
const { api } = useApi()
const { user: currentUser } = storeToRefs(useUserStore())
const { socket, isConnected } = storeToRefs(useSocketStore())
const { fetchQuotes } = useQuote()
const { fetchUserInfo } = useUser()
const { fetchActivities } = useActivity()
const { fetchTransactions } = useTransaction()
const { inviteId, queueId } = useRoute().query
const { logError } = useSentry()

const drawName = ref('')
const totalDrawParticipants = ref(0)
const amountToBeWon = ref(0)
const showUserJoin = ref(false)
const userJoined = ref('')
const members = ref<any[]>([])
const canExistDraw = ref(false)
const loading = ref({
  exist: false,
})
const confirmModal = ref<InstanceType<typeof ConfirmModal> | null>(null)
const showConfirmModal = ref(false)

watchEffect(() => {
  if (confirmModal.value !== null) {
    confirmModal.value.openModal()
  }
})

watch(isConnected, (val) => {
  if (val) {
    bindSocketEvents()
  }
})

onUnmounted(() => {
  localStorage.removeItem('animationHasPlayed')
  disconnectSocket()
  fetchResources()
})

onMounted(async () => {
  socket.value.on('connect_error', () => {
    socket.value.connect()
  })

  if (!socket.value.connected) {
    socket.value.connect()
  } else {
    initializeDraw()
  }
})

function initializeDraw() {
  bindSocketEvents()
  fetchQuotes(1)
}
function bindSocketEvents() {
  socket.value.emit('queue', {
    queueId: queueId,
    inviteId: inviteId || null,
  })
  socket.value.on('list queue users', (data: any) => {
    members.value = data.users
    const loggedInUser = data.queue.grouping.find(
      (u: any) => u.userId === currentUser.value?.id,
    )
    if (loggedInUser) {
      fetchServerTime(loggedInUser.createdAt)
    }
  })
  socket.value.on('queue addition', async (data: any) => {
    members.value.push(data.user)
    if (currentUser.value?.id !== data.user.id) {
      userJoined.value = data.user.nickname
      showUserJoin.value = true
      await sleep(2000)
      showUserJoin.value = false
    }
  })
  socket.value.on('draw winner', (data: any) => {
    isCountingDown.value = true
    startDrawCountDown(data.user)
  })
  socket.value.on('draw info', (data: any) => {
    drawName.value = data.group.name.toLowerCase()
    totalDrawParticipants.value = data.group.participants
    amountToBeWon.value = Number(
      data.subGroup.amount * totalDrawParticipants.value -
        data.subGroup.evaluation,
    )
  })
  socket.value.on('error', (data: any) => {
    redirectUserOnError(
      data.message || 'Something went wrong, could not join draw, Try Again',
    )
  })
}

// Animation and countdown
const animationList = ['bounce', 'shuffle', 'slide-up']
const timerCountDown = ref(5)
const isCountingDown = ref(false)
const animationDuration = 10000
const winner = ref('')
const isCurrentLoggedInUserWinner = ref(false)
let drawCountDownInterval: ReturnType<typeof setInterval>
let bounceAnimationInterval: ReturnType<typeof setInterval>
let slideInterval: ReturnType<typeof setInterval>
const drawParticipantIndex = ref(0)
const drawWinnerOverlay = ref<InstanceType<typeof DrawWinnerOverlay> | null>(
  null,
)
const showDrawOverlay = ref(false)

watchEffect(() => {
  if (drawWinnerOverlay.value !== null) {
    drawWinnerOverlay.value.open()
  }
})

const animationType = computed(() => {
  const number = generateRandomNumber(animationList.length, 1)
  if (number === 1) {
    return 'bounce'
  } else if (number === 2) {
    return 'shuffle'
  } else {
    return 'slide-up'
  }
})

function startDrawCountDown(user: any) {
  drawCountDownInterval = setInterval(() => {
    timerCountDown.value--
    if (timerCountDown.value <= 0) {
      clearInterval(drawCountDownInterval)
      timerCountDown.value = 0
      isCountingDown.value = false
      if (animationType.value === 'bounce') {
        startBounceAnimation(user)
      } else if (animationType.value === 'shuffle') {
        startShuffleAnimation(user)
      } else {
        startSlideAnimation(user)
      }
    }
  }, 1000)
}
async function startBounceAnimation(user: any) {
  bounceAnimationInterval = setInterval(() => {
    drawParticipantIndex.value = generateRandomNumber(members.value.length, 1)
  }, 200)
  await sleep(animationDuration)
  clearInterval(bounceAnimationInterval)
  const winnerIndex = members.value.findIndex(
    (member) => member.nickname.toLowerCase() === user.nickname,
  )
  drawParticipantIndex.value = Number(winnerIndex + 1)
  updateWinnerAndShowOverlay(user)
}

const nameToShuffleFrom = ref('nickname of winner here')
const alphanumeric = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ12345678910_-#$%^&*()_=!{}|?'
const hasShuffleAnimationStarted = ref(false)
let shuffleAnimationInterval: ReturnType<typeof setInterval>

async function startShuffleAnimation(user: any) {
  hasShuffleAnimationStarted.value = true
  shuffleAnimationInterval = setInterval(() => {
    nameToShuffleFrom.value = nameToShuffleFrom.value
      .split('')
      .map(() => alphanumeric[Math.floor(Math.random() * alphanumeric.length)])
      .join('')
  }, 30)
  await sleep(animationDuration)
  clearInterval(shuffleAnimationInterval)
  nameToShuffleFrom.value = ''
  hasShuffleAnimationStarted.value = false
  updateWinnerAndShowOverlay(user)
}

const firstValue = ref('')
const secondValue = ref('')
const hasSlideAnimationStarted = ref(false)
async function startSlideAnimation(user: any) {
  hasSlideAnimationStarted.value = true
  slideInterval = setInterval(() => {
    firstValue.value =
      members.value[generateRandomNumber(members.value.length - 1, 0)].nickname
    secondValue.value =
      members.value[generateRandomNumber(members.value.length - 1, 0)].nickname
  }, 100)
  await sleep(animationDuration)
  clearInterval(slideInterval)
  updateWinnerAndShowOverlay(user)
  // hasSlideAnimationStarted.value = false
}
function updateWinnerAndShowOverlay(user: any) {
  if (currentUser.value?.id === user._id) {
    isCurrentLoggedInUserWinner.value = true
  } else {
    isCurrentLoggedInUserWinner.value = false
  }
  winner.value = user.nickname
  showDrawOverlay.value = true
  drawWinnerOverlay.value?.open()
}

// ACTIONS
function openConfirmModal() {
  showConfirmModal.value = true
  confirmModal.value?.openModal()
}
async function fetchServerTime(queueDate: string) {
  try {
    const response = await api.draw.fetchServerTime()
    if (response.success) {
      const date = new Date(queueDate)
      date.setHours(date.getHours() + 48)
      const serverDate = new Date(response.data)
      if (serverDate > date) {
        canExistDraw.value = true
      }
    }
  } catch (err) {
    logError(err)
  }
}
function disconnectSocket() {
  if (socket.value.connected) {
    socket.value.disconnect()
    socket.value.connect()
  }
}
function redirectUserOnError(msg: string) {
  disconnectSocket()
  app.showAlertModal({
    status: 'info',
    title: msg,
  })
  navigateToPath()
}
function redirectToHomePage() {
  drawWinnerOverlay.value?.closeOverlay()
  navigateToPath()
}
function fetchResources() {
  fetchUserInfo()
  fetchActivities()
  fetchTransactions()
}
function navigateToPath() {
  if (inviteId) {
    return navigateTo('/games/quickdraw/invite?type=history', { replace: true })
  } else {
    return navigateTo('/home', { replace: true })
  }
}
async function exitDraw() {
  try {
    loading.value.exist = true
    const response = await api.draw.exitDraw(queueId as string)
    loading.value.exist = false
    if (response) {
      app.showAlertModal({
        status: 'success',
        title: 'Successfully exited draw',
      })
      navigateToPath()
    }
  } catch (err) {
    loading.value.exist = false
    app.showAlertModal({
      status: 'error',
      title: formatErrorResponse(err),
    })
    logError(err)
    redirectUserOnError('Error occurred existing draw')
  }
}
</script>
