<template>
  <div class="flex w-fit items-center gap-1">
    <div
      class="size-2 shrink-0 animate-pulse rounded-full shadow-custom"
      :class="[isConnected ? 'bg-green-500' : 'bg-red-500']"
    />
    <p
      class="text-xs font-bold"
      :class="[isConnected ? 'text-green-500' : 'text-red-500']"
    >
      {{ isConnected ? 'Connected' : 'Reconnecting...' }}
    </p>
  </div>
</template>

<script setup lang="ts">
// Reusable composable
const { isConnected } = storeToRefs(useSocketStore())
</script>
