<template>
  <div>
    <h1 class="text-center text-2xl font-bold capitalize md:text-3xl">
      {{ drawName }} Draw
    </h1>
    <div class="mb-2 flex justify-end">
      <SocketStatus />
    </div>
    <FMDrawStats :winnings="amountToBeWon" :staked="amountStaked" />
    <div v-if="members.length" class="mt-5">
      <p
        class="mb-1 text-center text-xs text-light-text dark:text-dark-light-text"
      >
        <strong class="text-sm text-primary"> {{ 100 - progress }}% </strong>
        Participants remaining
      </p>
      <UiProgressBar :progress="progress" />
    </div>
    <div
      class="mt-5 rounded-lg border border-grey bg-white p-5 shadow-custom dark:border-grey/10 dark:bg-dark-secondary"
    >
      <LazyDrawCountDown v-if="isCountingDown" :countdown="timerCountDown" />
      <template v-else>
        <p
          class="text-center text-xs font-bold uppercase tracking-wider text-light-text dark:text-dark-light-text"
        >
          {{
            members.length === totalDrawParticipants
              ? 'Draw in Action'
              : 'Participants in Draw'
          }}
        </p>
        <div
          v-if="winner"
          class="mt-5 animate-bounce text-center text-lg font-bold"
        >
          @{{ winner }}
        </div>
        <template v-if="!winner">
          <LazySlideUpAnimation
            v-if="animationType === 'slide-up'"
            :first-value="firstValue"
            :second-value="secondValue"
            :members="members"
            :has-slide-animation-started="hasSlideAnimationStarted"
          />
        </template>
      </template>
    </div>

    <LazyDrawWinnerOverlay
      v-if="showDrawOverlay"
      ref="drawWinnerOverlay"
      :nickname="winner"
      :amount="amountToBeWon"
      :is-winner="isCurrentLoggedInUserWinner"
      @go-back-to-draw-listing="redirectToHomePage"
    />
  </div>
</template>

<script setup lang="ts">
import type DrawWinnerOverlay from '@/components/pages/games/draw/quickdraw/DrawWinnerOverlay.vue'

// Reusable Composables
const app = useAppStore()
const { user: currentUser } = storeToRefs(useUserStore())
const { socket, isConnected } = storeToRefs(useSocketStore())
const { fetchQuotes } = useQuote()
const { fetchUserInfo } = useUser()
const { fetchActivities } = useActivity()
const { fetchTransactions } = useTransaction()
const { queueId } = useRoute().query

const drawName = ref('')
const totalDrawParticipants = ref(0)
const overflowParticipants = ref(0)
const amountToBeWon = ref(0)
const showUserJoin = ref(false)
const userJoined = ref('')
const amountStaked = ref(0)
const members = ref<any[]>([])

watch(isConnected, (val) => {
  if (val) {
    bindSocketEvents()
  }
})

onUnmounted(() => {
  disconnectSocket()
  fetchResources()
})

onMounted(async () => {
  socket.value.on('connect_error', () => {
    socket.value.connect()
  })

  if (!socket.value.connected) {
    socket.value.connect()
  } else {
    initializeDraw()
  }
})

const progress = computed(() => {
  return (
    Math.floor((members.value.length / overflowParticipants.value) * 100) || 0
  )
})
const animationType = computed(() => {
  // const number = generateRandomNumber(animationList.length, 1)
  return 'slide-up'
  /* if (number === 1) {
    return 'bounce'
  } else if (number === 2) {
    return 'shuffle'
  } else {
    return 'slide-up'
  } */
})

function initializeDraw() {
  bindSocketEvents()
  fetchQuotes(1)
}
function bindSocketEvents() {
  socket.value.emit('queue', {
    queueId: queueId,
    inviteId: null,
  })
  socket.value.on('list queue users', (data: any) => {
    members.value = data.users
  })
  socket.value.on('queue addition', async (data: any) => {
    members.value.push(data.user)
    if (currentUser.value?.id !== data.user.id) {
      userJoined.value = data.user.nickname
      showUserJoin.value = true
      await sleep(2000)
      showUserJoin.value = false
    }
  })
  socket.value.on('draw winner', (data: any) => {
    isCountingDown.value = true
    startDrawCountDown(data.user)
  })
  socket.value.on('draw info', (data: any) => {
    amountStaked.value = data.subGroup.amount as number
    drawName.value = data.group.name.toLowerCase()
    overflowParticipants.value = data.group.overflow
    totalDrawParticipants.value = data.group.participants
    amountToBeWon.value = Number(
      data.subGroup.amount * totalDrawParticipants.value -
        data.subGroup.evaluation,
    )
  })
  socket.value.on('error', (data: any) => {
    redirectUserOnError(
      data.message || 'Something went wrong, could not join draw, Try Again',
    )
  })
}

// Animation and countdown
const timerCountDown = ref(5)
const isCountingDown = ref(false)
const animationDuration = 10000
const winner = ref('')
const isCurrentLoggedInUserWinner = ref(false)
let drawCountDownInterval: ReturnType<typeof setInterval>
let slideInterval: ReturnType<typeof setInterval>
const drawWinnerOverlay = ref<InstanceType<typeof DrawWinnerOverlay> | null>(
  null,
)
const showDrawOverlay = ref(false)

watchEffect(() => {
  if (drawWinnerOverlay.value !== null) {
    drawWinnerOverlay.value.open()
  }
})

function startDrawCountDown(user: any) {
  drawCountDownInterval = setInterval(() => {
    timerCountDown.value--
    if (timerCountDown.value <= 0) {
      clearInterval(drawCountDownInterval)
      timerCountDown.value = 0
      isCountingDown.value = false
      startSlideAnimation(user)
    }
  }, 1000)
}

const firstValue = ref('')
const secondValue = ref('')
const hasSlideAnimationStarted = ref(false)
async function startSlideAnimation(user: any) {
  hasSlideAnimationStarted.value = true
  slideInterval = setInterval(() => {
    firstValue.value =
      members.value[generateRandomNumber(members.value.length - 1, 0)].nickname
    secondValue.value =
      members.value[generateRandomNumber(members.value.length - 1, 0)].nickname
  }, 100)
  await sleep(animationDuration)
  clearInterval(slideInterval)
  updateWinnerAndShowOverlay(user)
  // hasSlideAnimationStarted.value = false
}
function updateWinnerAndShowOverlay(user: any) {
  if (currentUser.value?.id === user._id) {
    isCurrentLoggedInUserWinner.value = true
  } else {
    isCurrentLoggedInUserWinner.value = false
  }
  winner.value = user.nickname
  showDrawOverlay.value = true
  drawWinnerOverlay.value?.open()
}

// ACTIONS
function disconnectSocket() {
  if (socket.value.connected) {
    socket.value.disconnect()
    socket.value.connect()
  }
}
function redirectUserOnError(msg: string) {
  disconnectSocket()
  app.showAlertModal({
    status: 'info',
    title: msg,
  })
  navigateToPath()
}
function redirectToHomePage() {
  drawWinnerOverlay.value?.closeOverlay()
  navigateToPath()
}
function fetchResources() {
  fetchUserInfo()
  fetchActivities()
  fetchTransactions()
}
function navigateToPath() {
  return navigateTo('/home', { replace: true })
}
</script>
