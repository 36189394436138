const { api } = useApi()

export default function useNotification() {
  const { quotes } = storeToRefs(useQuoteStore())
  const { logError } = useSentry()

  const loading = ref({
    quotes: false,
    error: false,
  })

  async function fetchQuotes(count: number) {
    try {
      loading.value.quotes = true
      loading.value.error = false
      const response = await api.quote.fetchQuotes(count)
      if (response) {
        quotes.value = response.data
      }
      loading.value.quotes = false
    } catch (err: any) {
      loading.value.quotes = false
      loading.value.error = true
      logError(err)
    }
  }

  return {
    loading,
    fetchQuotes,
  }
}
