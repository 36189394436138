<template>
  <div class="py-6">
    <div class="container">
      <FMDrawView v-if="type === 'fm'" />
      <RegularDrawView v-else />
    </div>
  </div>
</template>

<script setup lang="ts">
// Reusable Composables
const app = useAppStore()
const { type } = useRoute().query

app.setPageTitle('Draw Activity')

definePageMeta({
  layout: 'dashboard',
  middleware: 'auth',
})

useHead({
  title: 'Luckee | Draw Activity',
})
</script>
